/*
Copyright 2021 ChainSafe Systems
SPDX-License-Identifier: LGPL-3.0-only
*/
@font-face {
  font-family: "Neue Montreal";
  src: local("Neue Montreal"),
    url(./assets/fonts/Neue-montreal/NeueMontreal-Medium.otf) format("opentype");
}

body {
  margin: 0;
  font-family: Neue Montreal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
